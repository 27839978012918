import React from "react";
import {login} from "../actions/actions"
import {connect} from "react-redux";
import backend from "../api/backend";
const dsteem = require('dsteem'); 

class LoginParking extends React.Component {

    state = {username : "", error : "", loginType : ""};
    client = new dsteem.Client('https://api.steemit.com');

    login_steemlogin = () =>
    {
        window.open(process.env.NODE_ENV ===  "production" ? 'https://steemways.com:4003/auth' : "http://178.18.246.55:4002/auth",'',' scrollbars=yes,menubar=no,width=447,height=614, resizable=yes,toolbar=no,location=no,status=no')
    };

    render() {

        if (this.state.loginType === "") {
            document.body.classList.add('bg');
            return (
                <div className="wrapper fadeInDown"> 
                    <div id="formContent">

                        <div className="fadeIn first">
                            <img src="./Sentinels_Symbol_Gradient.png" alt="steem icon" style={{width: "150px"}}/>
                        </div>

                        <span style={{color : "red"}}>{this.state.error}</span>

                        <button type={"button"} className="btn btn-primary " onClick={this.login_steemlogin} style={{
                            backgroundColor: "white",
                            color: "#999999",
                            width: "235px",
                            marginTop: "20px",
                            border: "1px solid #999999",
                            borderRadius: "0"
                        }}>Log in with SteemLogin
                        </button>
                        <p style={{paddingTop:30,fontSize:13}}>Brough by <a href="https://steemlogin.com/sign/account_witness_vote?approve=1&amp;witness=seven.wit" target="_blank">Seven.wit</a></p>
                    </div>
                </div>
            )
        }
    }

}


const mapStateToProps = (state) => {
    return {
        logged_user : state.login};
};

export default connect(mapStateToProps, {login})(LoginParking);