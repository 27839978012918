import axios from 'axios';
// import https from 'https';
import "https-browserify" // Import the polyfill first
import https from 'https' // Then import the 'path' module
export default axios.create({
    baseURL : process.env.NODE_ENV ===  "production" ? 'https://steemways.com:4003/' : "http://178.18.246.55:4002",
    httpsAgent: new https.Agent({  
        rejectUnauthorized: false
      })
});

